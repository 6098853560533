<template>
  <div class="CourseCenter" :style="subTypeCourseList.length > 0 ? 'padding-bottom: 80px;' : 'padding-bottom:0px;'">
    <topNav :active="pageIndex" :historyActive="$route.params.oldHistory"></topNav>
    <!-- <img src="@/assets/img/course.jpg" alt=""> -->
    <!-- 面包屑 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>课程选择</el-breadcrumb-item>
    </el-breadcrumb>

    <!-- 分类 -->
    <div class="typer" v-loading="typeListLoading">
      <div class="typer-content">
        <span>一级分类：</span>
        <el-radio-group v-model="typeCourse">
          <el-radio v-for="(item, i) in typeList" :key="i" :label="item.name" border @change="gettypeCourse(item)">
          </el-radio>
        </el-radio-group>
      </div>
      <div class="typer-content" style="position: relative;">
        <span>二级分类：</span>
        <div class="move" style="width: 93.05%;" ref="move">
          <el-radio-group v-model="subTypeCourse">
            <el-radio label="全部" border @change="getsubTypeCourse()"></el-radio>
            <el-radio v-for="(item, i) in subTypeList" v-if="subClassify == item.parentId" :key="i" :label="item.name"
              border @change="getsubTypeCourse(item.id)"></el-radio>
          </el-radio-group>
        </div>
        <!-- <div class="move" style="width: 93.05%;" ref="move">
          <el-radio-group v-model="allSubTypeCourse">
            <el-radio border :label="1">全部</el-radio>
          </el-radio-group>
        </div> -->
        <div style="margin-top: 0.375rem;position: absolute;right: 0">
          <div style="cursor: pointer;" @click="clickTypeMove">
            <i class="cus-icon-down-1" v-if="!moveFlag"></i>
            <i class="cus-icon-up-1" v-if="moveFlag"></i>
          </div>
        </div>
      </div>
    </div>
    <!-- 全部课程 -->
    <div class="course-back" v-loading="allCourseListLoading">
      <div class="course">
        <div class="course-content">
          <div class="course-content-div" v-for="(item, i) in allCourseList" :key="i">
            <div class="content-img">
              <img v-if="item.smallCover" :src="$store.getters.vodDomainName+item.smallCover" @click="clickCourse(item)">
              <img v-if="!item.smallCover" src="@/assets/img/course.jpg" @click="clickCourse(item)">
              <!-- :src="item.smallCover" -->
              <!-- <div class="content-img-title">{{ item.name }}</div> -->
            </div>
            <div class="content-message">
              <div class="content-line-title" @click="clickCourse(item)">
                <el-tooltip class="item" effect="light" :content="item.name" placement="top">
                  <span>
                    {{ item.name }}
                  </span>
                </el-tooltip>
              </div>
              <div class="content-section">
                共{{ item.sectionNum }}小节
                <span class="content-credit">
                  共{{ item.creditHours }}学时
                </span>
              </div>
              <div class="content-created">
                创建时间:{{ item.created }}
                <span class="content-hits">
                  <i class="el-icon-user"></i>
                  {{ item.hits }}人报名
                </span>
              </div>
              <div class="content-certificatePrice">
                <span class="price">
                  ￥{{ item.certificatePrice }}
                </span>
                <!-- <span v-if="item.isJoin==true" >
                  已加入
                </span> -->
                <button v-if="item.isJoin == true" class="button" @click="gotoCourse(item)">进入课程</button>
                <button v-if="item.isJoin != true" class="button" @click="joinCourse(item,i)">加入课程</button>
              </div>
            </div>
          </div>
        </div>
        <div style="line-height: 200px;text-align: center;background-color: #fff;color: #909399;margin-bottom: 30px;"
          v-if="!allCourseList.length > 0">
          暂无课程
        </div>
      </div>
      <!--分页组件-->
      <el-pagination background @size-change="sizeChangeHandle" @current-change="currentChangeHandle"
        :current-page="page.pageIndex" :page-sizes="[10, 20, 50, 100]" :page-size="page.pageSize" :total="page.totalPage"
        layout="prev, pager, next" style="text-align: center;">
      </el-pagination>
    </div>

    <footerBottom></footerBottom>

    <!-- 用户选择的课时 -->
    <div class="certificates" v-if="this.$store.getters.oatoken">
      <div style="display: flex;line-height: 40px;text-align: center;">
        <!-- <div v-for="index in subTypeCourseList"> -->
        <div>
          <!-- <span style="font-size: 20px;color: #1e90ff;">{{ index }}</span> -->
          <span>
            总计：已选择<span style="font-size: 20px;color: #1e90ff;font-weight: bold;"> {{ userMsg["全部"].selected }} </span>门课程
          </span>
          <span style="margin-left: 30px;">
            学时：含有
            <span v-if="this.totalHoursSelect == '公共课14学时'" style="font-size: 20px;font-weight: bold;" :style="userMsg['全部'].totalHours==14?'color: #1e90ff;':'color: red;'">
              {{ userMsg["全部"].totalHours }}
            </span>
            <span v-if="this.totalHoursSelect == '公共课24学时'" style="font-size: 20px;font-weight: bold;"
            :style="userMsg['全部'].totalHours==24?'color: #1e90ff;':'color: red;'">
              {{ userMsg["全部"].totalHours }}
            </span>
            / {{ this.totalHoursSelect == "公共课14学时" ? "14" : "24" }} 学时
          </span>
        </div>
        <!-- </div> -->
        <!-- <div style="flex: 0.4;" v-if="userMsg['全部'].totalHours >= 14 && userMsg['全部'].totalHours < 24">
          <span style="color: #1e90ff;">已满足公共课14学时要求</span>
        </div> -->
        <div style="width: 200px;">
          <el-select v-model="totalHoursSelect">
            <el-option label="公共课14学时" value="公共课14学时">
            </el-option>
            <el-option label="公共课24学时" value="公共课24学时">
            </el-option>
          </el-select>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { httpGet, httpNPGet, httpNPPost } from "@/utils/httpRequest";
import topNav from "@/components/nav/nav.vue"
import footerBottom from '@/components/footer/footer'

export default {
  name: 'CourseCenter',
  data() {
    return {
      // 选择课程学识分类
      totalHoursSelect: "公共课14学时",
      // 课程分类加载
      typeListLoading: true,
      // 全部课程加载
      allCourseListLoading: true,
      //
      pageIndex: 1,
      // tip
      tipShow: true,
      imgUrl: ["../assets/be-首屏.png", "../assets/be-首屏.png"],
      //分类
      typeCourse: '全部',
      subTypeCourse: '全部',
      typeList: [],
      typeParentId: "",
      typeIds: [],
      //子类
      subTypeList: [],
      moveFlag: false,
      //  课程详情
      activeName: 'first',
      courseMenu: [
      ],
      allCourseList: [
      ],
      //分页对象
      page: {
        //当前页
        pageIndex: 1,
        //每页显示数据
        pageSize: 6,
        //总页数
        totalPage: 0,
      },
      subClassify: "",
      typeClassify: "",
      userMsg: {"全部": {}},
      subTypeCourseList: [],
      allSubTypeCourse: 1,
      sysConfigObj:''
    }
  },
  components: {
    topNav: topNav,
    footerBottom
  },
  created() {
    this.sysConfigObj = this.$store.getters.enterpriseObj

  },
  watch: {

  },
  mounted() {
    this.getCourses(this.typeParentId, this.typeIds, this.page);
    this.getCourseType();
    if (localStorage.getItem('oatoken')) {
      this.GetUserMsg();
    }
  },
  methods: {
    // 选择课程
    clickCourse(data) {
      // console.log(data)
      this.$router.push({
        name: 'CourseChoosing',
        query: {
          id: data.id,
          name: data.name,
          data: data
        }
      })
    },
    // 加入课程
    joinCourse(data,index) {
      // console.log(data)
      // console.log(this.totalHoursSelect)
      if (this.totalHoursSelect == "公共课24学时") {
        if (24 < data.creditHours + this.userMsg["全部"].totalHours) {
          // this.$message("已满足公共课24学时")
          this.$alert('添加该课程后已选学时将超出24学时，是否继续？', '学时超出', {
            showCancelButton: true,
            confirmButtonText: '继续',
            cancelButtonText: '取消',
            callback: action => {
              if (action == "confirm") {
                this.defineJoinCourse(data,index)
                this.totalHoursSelect = "公共课24学时"
              }
            }
          });
        }else {
          this.defineJoinCourse(data,index)
        }
      } else if (this.totalHoursSelect == "公共课14学时") {
        // console.log("公共课14学时")
        if (14 < data.creditHours + this.userMsg["全部"].totalHours) {
          this.$alert('添加该课程后已选学时将超出14学时，是否继续？', '学时超出', {
            showCancelButton: true,
            confirmButtonText: '继续',
            cancelButtonText: '取消',
            callback: action => {
              if (action == "confirm") {
                this.defineJoinCourse(data, index)
              }
            }
          });
        }else{
          this.defineJoinCourse(data, index)
        }
      } else {
        // console.log("defineJoinCourse")
        this.defineJoinCourse(data)
      }
    },
    defineJoinCourse(data,index) {
      if (localStorage.getItem("userId")) {
        httpNPPost("/app/app-user-course-study/add?courseId=" + data.id,
          (res) => {
            this.$message({
              message: '加入课程成功',
              type: 'success'
            });
            // this.getCourses();
            this.allCourseList[index].isJoin = true
            // console.log(this.allCourseList[index].isJoin)
            // console.log(this.allCourseList[index].isJoin = true)
            //   data.isJoin = true;
            this.GetUserMsg();
          },
          (err) => {
            if (err.code == 500) {
              if (err.msg == "课程记录已添加") {
                // throw err
                this.$message({
                  message: '已加入该课程，请不要重复添加',
                  type: 'error'
                });
              } else if (err.msg == "token异常，请重新登录") {
                localStorage.clear();
                if (!localStorage.getItem("userId")) {
                  this.$message.error('登录过期，请重新登录!')
                  this.$router.push("/login")
                }
              }
            }
            // console.log(err.msg)
          })
      }
    },
    // 进入课程
    gotoCourse(item) {
      // console.log(item)
      this.$router.push({
        path: "/videocatalog",
        query: {
          courseName: item.name,
          courseId: item.id
        }
      })
    },
    // 课程详情tab
    handleClick(tab, event) {
      // console.log(tab, event);
    },
    // 分类展开收缩
    clickTypeMove() {
      // 获取展开ref
      let list = this.$refs.move;
      // 获取收缩状态时的高度值
      let height = list.offsetHeight;
      if (!this.moveFlag) {
        // 展开样式
        list.style.height = 'auto';
        height = list.offsetHeight;
        list.style.height = 32 + 'px';
        // 计算高度
        document.body.offsetHeight;
        list.style.height = (height - 20) + 'px';
      } else {
        // 收缩样式
        list.style.height = 32 + 'px'
      }
      this.moveFlag = !this.moveFlag;
    },
    // 每页数
    sizeChangeHandle(val) {
      this.page.pageSize = val;
      this.page.pageIndex = 1;
    },

    // 当前页
    currentChangeHandle(val) {
      this.page.pageIndex = val;
      this.getCourses();
    },
    /**
     * 点击切换标题
     * @param {number} index 菜单序列(点击的第index个)
     */
    changeTitle(index) {
      this.active = index;

      // console.log('===========', index)
      this.setUnderLine();

      // if (index === 4 ) {
      //   this.$router.push="./videoplayback/videocatalog.vue";
      // }
    },
    //  ------------------------
    // 获取用户学习信息数
    GetUserMsg() {
      this.subTypeCourseList = []
      httpNPGet("/app/app-user-credit-hours/detail",
        (res) => {
          this.userMsg = res.data
          if (this.userMsg["全部"].totalHours >= 24) {
            this.totalHoursSelect = "公共课24学时"
          }
          this.subTypeCourseList.push("全部");
          for (const key in res.data) {
            // console.log(key);
            if (key !== "全部") {
              this.subTypeCourseList.push(key);
            }
          }
        }, (err) => {
          // // console.log(err)
        })
    },
    // 课程分类改变事件
    gettypeCourse(data) {
      // console.log(data)
      this.subClassify = data.id
      this.typeClassify = ""
      this.subTypeCourse = "全部"
      // console.log(this.typeClassify)
      // console.log(this.subClassify)
      if (data.name == '全部') {
        this.typeIds = []
      } else {
        // console.log(data.id)
        if (this.subClassify && this.typeClassify) {
          this.typeIds = [this.subClassify, this.typeClassify]
        } else if (this.subClassify) {
          this.typeIds = [this.subClassify]
          for (const index in this.subTypeList) {
            this.typeIds.push(this.subTypeList[index].id)
          }
          // console.log(this.typeIds)
        } else if (this.typeClassify) {
          this.typeIds = this.typeClassify
        }
      }
      this.getCourses();
    },
    // 类型分类改变事件
    getsubTypeCourse(id) {
      this.typeClassify = id
      if (this.subClassify && this.typeClassify) {
        this.typeIds = [this.subClassify, this.typeClassify]
      } else if (this.subClassify) {
        this.typeIds = [this.subClassify]
        for (const index in this.subTypeList) {
          this.typeIds.push(this.subTypeList[index].id)
        }
      } else if (this.typeClassify) {
        this.typeIds = this.typeClassify
      } else {
        this.typeIds = []
      }
      this.getCourses();
    },
    // 请求具体课程
    getCourses() {
      // console.log(this.page.pageIndex)
      this.allCourseListLoading = true
      httpGet("/course-info/list",
        {
          typeIds: this.typeIds.toString(),
          size: this.page.pageSize,
          current: this.page.pageIndex,
        }
        , (res) => {
          this.page.totalPage = res.data.total;
          // console.log(res.data)
          this.allCourseList = res.data.records;
          this.allCourseListLoading = false;
        }, (err) => {

          // throw err
        })
    },
    getCourseType() {
      httpNPGet("/course-type/list", (res) => {
        // console.log(res)
        this.typeList = this.getTypeList(res.data)
        this.subTypeList = this.getSubTypeList(res.data)
        this.typeListLoading = false
        this.typeList.unshift({ name: "全部" })
        // this.subTypeList.unshift({ name: "全部" })
      }
      )
    },
    // 过滤课程
    getTypeList(data) {
      return data.filter(item =>
        item.layer == 0
      )
    },
    // 过滤分类
    getSubTypeList(data) {
      return data.filter(item =>
        !item.layer == 0
      ).sort((a, b) => -(a.created.localeCompare(b.created)))
    }
  }
}
</script>

<style lang="scss" scoped>
.logo {
  width: 375px;
  height: 80px;
}

.container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

.header {
  height: 40px;
  line-height: 40px;
  background-color: #303030;
  color: aliceblue;
  font-size: 14px;

  .header-top {
    display: flex;
    justify-content: space-between;
    background-color: #303030;
    color: aliceblue;
  }

  .header-top-right,
  .header-top-right>div {
    display: flex;
    align-items: center;
  }

  .header-top-right img {
    width: 16px;
    height: 16px;
    margin: 0 10px;
  }
}

.nav {
  width: 100%;
  position: absolute;
  top: 40px;
  left: 0;
  z-index: 999;
  background: rgba(0, 0, 0, 0.05);
  box-sizing: border-box;
  box-shadow: 0px 3px 12px 0px rgba(0, 36, 153, 0.06);
  border: 1px solid rgba(255, 255, 255, 0.14);
  color: #fff !important;
}

.nav-f {
  width: 100%;
  box-shadow: 0px 3px 12px 0px rgba(0, 36, 153, 0.06);
  //position: fixed;
  //top: 0;
  //left: 0;
  background: #fff;
  box-sizing: border-box;
  border: 1px solid rgba(255, 255, 255, 0.14);
  color: #333 !important;
}

.nav1 {
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .nav-left {
    display: flex;
    /* justify-content: center; */
    align-items: center;
    height: 80px;
    /* line-height: 80px; */

    .nav-left h2 {
      font-size: 24px;
      font-family: Roboto-SemiBold, Roboto;
      font-weight: 600;
      line-height: 76px;
      letter-spacing: 2px;
    }

    .nav-left span {
      width: 1px;
      height: 26px;
      background: #ffffff;
      border-radius: 0px 0px 0px 0px;
      margin: 0 15px;
      opacity: 0.2;
    }
  }

  .nav-center {
    width: 600px;

    .nav-list {
      /* margin: 0 auto;
      max-width: 600px; */
      display: flex;
      justify-content: space-around;
      align-items: center;
      height: 78px;
    }

    .nav-list>div {
      width: 600px;
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC, sans-serif;
      font-weight: 400;
      line-height: 16px;
      cursor: pointer;
      text-align: center;
      border-right: solid 0.2px #d0d0d0;
    }

    .nav-list>div:last-child {
      border: none;
    }

    .nav-list--active {
      font-family: PingFangSC-Semibold, PingFang SC, sans-serif;
      font-weight: bold;
    }
  }

  .nav-line {
    margin: 0 auto;
  }

  .nav-line>div {
    /*width: 32px;*/
    height: 4px;
    background-color: #1371E7;
    /*background-image: linear-gradient(43deg, #4158D0 0%, #C850C0 46%, #FFCC70 100%);*/
    border-radius: 2px;
    transition-duration: 0.3s;
    position: relative;
    top: 0px;
  }

  .nav-center-btn {
    /* height: 40px; */
    width: 122px;
    height: 40px;
    background: #1371e7;
    border-radius: 4px 4px 4px 4px;
    border: 0;
    opacity: 1;
    font-size: 16px;
    cursor: pointer;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #ffffff;
  }


}

// ::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active {
//   color: #FFF;
//   width: 66px;
//   height: 36px;
//   line-height: 36px;
//   background: #1371E7;
//   border-radius: 2px 2px 2px 2px;
//   opacity: 1;
//   border: 1px solid #EDEFF2;
// }

// ::v-deep .el-pagination.is-background .btn-next,
// ::v-deep .el-pagination.is-background .btn-prev,
// ::v-deep .el-pagination.is-background .el-pager li {
//   width: 66px;
//   height: 36px;
//   background: #FFFFFF;
//   border-radius: 2px 2px 2px 2px;
//   opacity: 1;
//   border: 0.0625rem solid #EDEFF2;
//   border-radius: 2px;
// }
.filter {
  box-sizing: border-box;
  width: 61.458%;
  background-color: #fff;
  margin: 0 auto 20px;
  padding: 0 1.25em;
  min-width: 1200px;
}

.course-back {
  box-sizing: border-box;
  width: 1200px;
  // background-color: #fff;
  margin: 20px auto 30px;
  height: 100%;
  min-width: 1200px;
  padding: 0;

  .course {
    margin: 0 auto;

    .course-line {
      display: flex;
      justify-content: space-between;
      padding: 0 0 1.375rem 0;

      .course-title {
        font-size: 1.0625rem;
        font-family: PingFang SC-Semibold, PingFang SC;
        font-weight: 600;
        color: #14171A;
        line-height: 1.5rem;
      }

      .course-number {
        font-size: 1rem;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #667280;
        line-height: 1.375rem;
      }
    }

    .course-content {
      display: grid;
      grid-template-columns: repeat(auto-fill, 49%);
      justify-content: space-between;
      min-height: 400px;


      .course-content-div {
        position: relative;
        width: 100%;
        // height: 12.5rem;
        background: #FFFFFF;
        border-radius: 10px;
        overflow: hidden;
        margin-bottom: 1.75rem;
        // cursor: pointer;
        padding: 1.25em;
        box-sizing: border-box;

        .content-img {
          // position: relative;
          float: left;
          width: 15rem;
          height: 10rem;

          img {
            cursor: pointer;
            width: 100%;
            height: 100%;
          }

          .content-img-title {
            position: absolute;
            top: 40%;
            left: 15%;
            width: 71.42857%;
            font-size: 1.0625rem;
            font-family: PingFang SC-Semibold, PingFang SC;
            font-weight: 600;
            color: #FFFFFF;
            line-height: 1.25rem;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
          }

          .content-img-title-1 {
            position: absolute;
            top: 28%;
            left: 15%;
            width: 71.42857%;
            font-size: 1.0625rem;
            font-family: PingFang SC-Semibold, PingFang SC;
            font-weight: 600;
            color: #FFFFFF;
            line-height: 1.25rem;
          }
        }

        .content-message {
          float: right;
          width: 18rem;
          height: 10rem;

          .content-line-title {
            cursor: pointer;
            text-align: left;
            font-size: 18px;
            color: #222222;
            font-weight: bold;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
          }

          .content-section {
            color: #1e90ff;
            font-weight: bold;
            text-align: left;
            font-size: 14px;
            height: 22px;
            margin-top: 30px;

            .content-credit {
              display: block;
              float: right;
              line-height: 22px;
              color: #0f72d8;
              display: inline-block;
              padding: 0 10px;
              border-radius: 10px;
              font-weight: 100;
              background-color: #eef4ff;
            }
          }

          .content-created {
            color: #9199a1;
            font-size: 14px;
            text-align: left;
            margin-top: 10px;

            .content-hits {
              float: right;
            }
          }

          .content-certificatePrice {
            width: 49%;
            position: absolute;
            bottom: 20px;

            .price {
              float: left;
              font-size: 20px;
              color: #DC143C;
            }

            .button {
              float: right;
              margin: 0 auto;
              background-color: #1e90ff;
              border: none;
              outline: none;
              color: #fff;
              line-height: 28px;
              padding: 0 15px;
              border-radius: 14px;
              cursor: pointer;
            }
          }
        }
      }
      .course-content-div:hover{
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.074);
        transition: 0.3s;
      }
    }
  }

}

.tip {
  width: 61.458%;
  margin: 1.25rem auto 1.75rem auto;
  background: rgba(242, 74, 74, 0.05);
  border-radius: 0.25rem 0.25rem 0.25rem 0.25rem;
  height: 5.5rem;
  display: flex;
  align-items: center;
  min-width: 1200px;

  img {
    width: 3.898%;
    margin: 1.65625rem 0 1.65625rem 1.5rem;
  }

  .tip-content {
    width: 88.136%;
    text-align: left;
    margin: 1.25rem 1rem;
    font-size: 0.875rem;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #F24A4A;
    line-height: 1.5rem;
  }
}

.typer {
  box-sizing: border-box;
  border-radius: 10px;
  width: 61.458%;
  text-align: left;
  margin: 0 auto 1.25rem auto;
  padding-bottom: 1.25rem;
  border-bottom: solid 0.0625rem #EDEFF2;
  background-color: #fff;
  min-width: 1200px;
  padding: 1.25em 1.25em 1.25em 1.25em;

  .move {
    height: 1.9375rem;
    overflow: hidden;
    transition: height .3s;
  }

  .typer-content {
    display: flex;
    flex-wrap: wrap;
    font-size: 0.8125rem;

    span {
      padding-top: 0.1875rem;
    }

    ::v-deep .el-radio__inner {
      display: none;
    }

    ::v-deep .el-radio {
      border-radius: 4px 4px 4px 4px;
      padding: 0 8px;
      height: 30px;
      line-height: 28px;
      border: 1px solid #EDEFF2;
      margin: 0 12px 20px 12px;
      cursor: pointer;
      font-size: 0.8125rem;
    }

    ::v-deep .el-radio:hover {
      color: #1371E7;
    }

    ::v-deep .el-radio__input.is-checked+.el-radio__label {
      color: #1371E7;
    }

    ::v-deep .el-radio.is-checked {
      color: #2080F7;
      background: rgba(32, 128, 247, 0.1);
    }

    ::v-deep .el-radio__label {
      padding-left: 0;
    }
  }
}

.el-breadcrumb {
  font-size: 0.8125rem;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #3E454D;
  line-height: 4rem;
  width: 61.458%;
  height: 4rem;
  margin: 0 auto;
  border-bottom: solid 0.0625rem #EDEFF2;
  min-width: 1200px;
  padding: 0;
}

::v-deep .el-breadcrumb__inner.is-link {
  font-size: 0.8125rem !important;
  font-weight: 400 !important;
}

.bottom {
  width: 100%;
  min-width: 1200px;
  height: 2.75rem;
  background: #181B20;
  font-size: 0.8125rem;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 1.75rem;

  .bottom-content {
    width: 61.458%;
    height: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .bottom-right {
    display: flex;
    align-items: center;
  }

  .bottom-right::before {
    content: "";
    display: inline-block;
    width: 1.25rem;
    height: 1.25rem;
    background: url('~@/assets/img/pre/bradge.png');
    background-size: 100% 100%;
    margin-right: 1.375rem;
  }
}

.footer {
  padding-top: 30px;
    padding-bottom: 20px;
    box-sizing: border-box;
    width: 100%;
    // height: 13rem;
    opacity: 1;
    background-image: url('~@/assets/img/pre/bg-footer.png');

  .footer-content {
    width: 61.458%;
    display: flex;
    justify-content: space-between;
    margin: 0 auto 1.5rem auto;
    padding: 2rem 0 0 0;
    align-items: center;

    .footer-top-line-1 {
      width: inherit;
      height: 0;
      opacity: 0.4;
      border: 0.0625rem solid;
      border-image: linear-gradient(191deg, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0)) 2 2;
    }

    .footer-top-line-2 {
      width: inherit;
      height: 0;
      opacity: 0.4;
      border: 0.0625rem solid;
      border-image: linear-gradient(191deg, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0)) 2 2;
      transform: rotate(180deg);
    }

    .footer-top-line-font {
      margin: 0 1.1%;
      font-size: 0.75rem;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(255, 255, 255, 0.47);
      line-height: 1.25rem;
      width: 70%;
    }

    @media screen and (max-width: 1539px) {
      .footer-top-line-font {
        width: 120%;
      }
    }

    @media screen and (max-width: 1400px) {
      .footer-top-line-font {
        width: 130%;
      }
    }
  }

  .footer-detail {
    width: 61.458%;
    //background-color: red;
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    text-align: left;
    font-size: 0.875rem;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 1.25rem;
    align-items: center;

    .line {
      width: 1px;
      height: 0.875rem;
      background: #FFFFFF;
      opacity: 0.2;
    }

    .code-logo {
      width: 35.87%;
    }

    .code-img {
      // width: 100%;
      height: 5.9375rem;
    }

    ul {
      list-style-type: none;
      margin-top: 1.75rem;
      display: flex;
      justify-content: space-between;

    }
  }
}

.certificates {
  box-sizing: border-box;
  bottom: 0px;
  box-shadow: 0 -1px 21px rgba(0, 0, 0, 0.12);
  position: fixed;
  width: 100%;
  background-color: #fff;
  padding: 25px 5% 15px 5%;
  font-size: 16px;
  color: rgb(58, 58, 58);

  div {
    flex: 1;
  }

  .certificates-left {
    float: left;
    line-height: 40px;
  }

  .certificates-right {
    float: right;

    button {
      width: 100px;
      height: 40px;
      font-size: 18px;
      background-color: #1e90ff;
      color: #fff;
      border: none;
      border-radius: 5px;
      outline: none;
      cursor: pointer;
    }
  }
}

.CourseCenter {
  width: 100%;
  height: 100%;
  background-color: rgb(247, 247, 247);
  // padding-bottom: 80px;
  /*position: absolute;*/
  /*top: 50%;*/
  /*left: 50%;*/
  /*transform: translate(-50%,-50%);*/
  /*width: auto;*/
  /*height:auto;*/
}

.nav {
  width: 100%;
  height: 5rem;
  background-color: rgba(203, 186, 186, 0.24);
}

.nav .nav-list {
  margin: 0 auto;
  max-width: 37.5rem;
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 4.75rem;
  /*background: #d40d0d;*/
}

.nav .nav-list>div {
  width: 100%;
  font-size: 1rem;
  font-family: PingFangSC-Regular, PingFang SC, sans-serif;
  font-weight: 400;
  color: #666;
  line-height: 1rem;
  cursor: pointer;
  text-align: center;
  border-right: solid;
  /*background-image: linear-gradient(43deg, #4158D0 0%, #C850C0 46%, #FFCC70 100%);*/
  /*-webkit-background-clip: text;*/
  /*-webkit-text-fill-color: transparent;*/

}

.nav .nav-list>div:last-child {
  border: none;
}

.nav .nav-list .nav-list--active {
  font-family: PingFangSC-Semibold, PingFang SC, sans-serif;
  font-weight: bold;
  color: #333333;

  /*transition: all 0.3s;*/
  /*background-color: #FFE53B;*/
  /*background-image: linear-gradient(147deg, #FFE53B 0%, #FF2525 74%);*/
}

.nav-line {
  margin: 0 auto;
}

.nav-line>div {
  /*width: 32px;*/
  height: 0.25rem;
  background-color: #333333;
  /*background-image: linear-gradient(43deg, #4158D0 0%, #C850C0 46%, #FFCC70 100%);*/
  border-radius: 0.125rem;
  transition-duration: 0.3s;
  position: relative;
  top: 0;
}

.top {
  width: 100%;
  height: 2.5rem;
  background: #303030;
}

::v-deep .el-tabs__nav-wrap::after {
  height: 0.0625rem;
  opacity: 0;
}
</style>
